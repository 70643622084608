import styles from "./Frame.module.css";

const Frame = () => {
  return (
    <div className={styles.groupParent}>
      <div className={styles.rectangleParent}>
        <div className={styles.groupChild} />
        <div className={styles.groupItem} />
        <div className={styles.submittedSuccessfully}>
          Submitted Successfully
        </div>
        <img
          className={styles.orderCompletedIcon}
          alt=""
          src="/order-completed@2x.png"
        />
      </div>
    </div>
  );
};

export default Frame;
