import { useState, useCallback } from "react";
import Frame from "../components/Frame";
import PortalPopup from "../components/PortalPopup";
import styles from "./Main.module.css";
import InputComponent from "../components/Input/input";
import Button from "../components/FButton/button";
import Navbar from "../components/Navbar/navbar";
import Card from "../components/Card/card";


const Main = () => {
  const [isFrameOpen, setFrameOpen] = useState(false);



  const openFrame = useCallback(() => {
    setFrameOpen(true);
  }, []);

  const closeFrame = useCallback(() => {
    setFrameOpen(false);
  }, []);

 

  return (
    <>
      <Navbar />
      
      <div className={styles.main}>
        <section id="home" className={styles.homeSection}>
          <div className={styles.homeContainerL}>
            <h1 className={styles.title}>AQPG - AUTOMATIC QUESTION<br /> PAPER GENERATING<br /> PLATFORM</h1>
            <p className={styles.description}>"Introducing our AI-powered Question Paper Generator: effortlessly
              create custom question papers in just a few clicks! Save time and
              ensure variety with automatic question generation based on your
              criteria. Streamline your workflow and enhance assessment quality
              with our intuitive web app."</p>
            <div className={styles.Wloginbtn}>
              <button className={styles.loginbtn}>
                <p className={styles.loginp}>Learn More</p>
              </button>
            </div>
          </div>
          <div className={styles.homeContainerR}>
            <img className={styles.image1} alt="" src="/image1.png" />
          </div>
        </section>

        {/* ABOUT */}
        <section id="about" className={styles.aboutSection} >
          <svg xmlns="http://www.w3.org/2000/svg" width="1281" height="155" viewBox="0 0 1281 155" fill="none">
            <path d="M1280.12 0L0 0V110.842C28.6837 75.4283 124.416 19.1624 286.345 69.335C324.898 81.2804 359.528 93.4329 391.6 104.687C529.675 153.141 620.325 184.951 772.383 111.948C779.829 108.373 787.104 104.866 794.223 101.434L794.281 101.406C966.251 18.4986 1047.05 -20.4535 1253.14 89.0025C1260.78 92.8956 1269.87 96.5846 1280.12 99.6643V0Z" fill="url(#paint0_linear_12_54)" />
            <defs>
              <linearGradient id="paint0_linear_12_54" x1="-0.879582" y1="84.9271" x2="1544.33" y2="84.9272" gradientUnits="userSpaceOnUse">
                <stop stop-color="#8C52FF" />
                <stop offset="0.29" stop-color="#9158EC" />
                <stop offset="0.8" stop-color="#682EB4" />
              </linearGradient>
            </defs>
          </svg>
          <div className={styles.aboutContainerL}>
            <h1 className={styles.title}>ABOUT AQPG</h1>
            <p className={styles.description}>
              "Welcome to our AQPG! We're a team passionate about revolutionizing
              the way question papers are created. Our AI-based automatic question
              paper generator web app simplifies the process, saving educators
              valuable time while ensuring quality assessments. With cutting-edge
              technology and a commitment to innovation, we're dedicated to
              empowering educators worldwide. Join us in shaping the future of
              education!"
            </p>
          </div>
          <div className={styles.aboutContainerR}>
            <img className={styles.aboutimg} alt="" src="/aboutimg.png" />
          </div>
        </section>
        <section id="features" className={styles.featuresSection}>
          <div className={styles.featuresbg}>
            <svg xmlns="http://www.w3.org/2000/svg" width="1281" height="601" viewBox="0 0 1281 601" fill="none">
              <path d="M1280.12 600.353V0.000183105C1242.87 3.05928 1211.3 14.1755 1190.52 25.8637C974.602 151.787 883.847 119.519 690.679 50.8378C682.663 47.9875 674.469 45.0745 666.084 42.1074C495.305 -18.3177 399.119 20.7815 252.609 80.3364C218.579 94.1695 181.832 109.107 140.807 124.156C87.7363 143.624 40.7394 152.513 0.120361 154.529V600.353L1280.12 600.353Z" fill="url(#paint0_linear_12_53)" />
              <defs>
                <linearGradient id="paint0_linear_12_53" x1="-0.759138" y1="328.944" x2="1544.3" y2="328.944" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#8C52FF" />
                  <stop offset="0.29" stop-color="#9158EC" />
                  <stop offset="0.8" stop-color="#682EB4" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className={styles.featurescard}>
            <Card
              title="Smart Question Generation"
              imageSrc="/Data points.gif"
            />
            <Card
              title="Customizable Question Settings"
              imageSrc="/Advanced customization.gif"
            />
            <Card
              title="Time-Saving Automation"
              imageSrc="/Time management.gif"
            />
          </div>
        </section>

        <section id="contact" className={styles.contactsection}>
          <div className={styles.contactbg}>
            <svg xmlns="http://www.w3.org/2000/svg" width="1280" height="222" viewBox="0 0 1280 222" fill="none">
              <path d="M1280 6.10352e-05V221.853C1242.75 218.794 1211.18 207.678 1190.39 195.989C974.482 70.0664 883.727 102.335 690.559 171.015C682.542 173.866 674.349 176.779 665.963 179.746C495.185 240.171 398.998 201.072 252.489 141.517C218.458 127.684 181.712 112.747 140.686 97.6971C87.616 78.2294 40.619 69.3405 0 67.3242V0L1280 6.10352e-05Z" fill="url(#paint0_linear_12_45)" />
              <defs>
                <linearGradient id="paint0_linear_12_45" x1="-0.8795" y1="121.557" x2="1544.18" y2="121.557" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#8C52FF" />
                  <stop offset="0.29" stop-color="#9158EC" />
                  <stop offset="0.8" stop-color="#682EB4" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className={styles.contactCard}>
            <div className={styles.Cform}>
              <b className={styles.sendUsA}>Send us a message</b>
              <InputComponent width="372px" label="Name" />
              <InputComponent width="372px" label="Email" />
              <div className={styles.TextArea}>
                <textarea className={styles.textArea} />
                <label className={styles.userLabel} >Enter your Message</label>

              </div>
              <Button
                width="10rem"
                color="#fff"
                bgColor="#8C52FF"
                svgPath="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                buttonText="Send"
                onClick={openFrame}
              />
            </div>
            <div className={styles.contactaddress}>
              <h1 className={styles.contacthead}>CONTACT US</h1>
              <div className={styles.contactdetails}>
                <div className={styles.address}>
                  <img className={styles.locationIcon} alt="" src="/location@2x.png" />
                  <p>SSM Polytechnic college
                    PB No.1, Thekkummuri PO<br />
                    Tirur Malappuram Dt.<br />
                    Kerala -676 105</p>
                </div>
                <div className={styles.phone}>
                  <img className={styles.callIcon} alt="" src="/call@2x.png" />
                  <p>0494-2422234</p>
                </div>
                <div className={styles.email}>
                <img className={styles.mailIcon} alt="" src="/mail@2x.png" />
                <p>ssmtirur@gmail.com</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className={styles.topbtn}>
        <a href="#home">
        <img className={styles.totop} alt="" src="/forward@2x.png" />
        </a>

      </div>
      </div>
      {isFrameOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top center"
          onOutsideClick={closeFrame}
        >
          <Frame onClose={closeFrame} />
        </PortalPopup>
      )}
    </>
  );
};

export default Main;
