import { useState,useCallback } from "react";
import Frame from "../components/Frame";
import PortalPopup from "../components/PortalPopup";
import styles from "./style.module.css";
import InputComponent from "../components/Input/input";
import Button from "../components/FButton/button";
import Navbar from "../components/Navbar/navbar";
import Card from "../components/Card/card";

const Component = () => {
  const [isFrameOpen, setFrameOpen] = useState(false);
  const openFrame = useCallback(() => {
    setFrameOpen(true);
  }, []);

  const closeFrame = useCallback(() => {
    setFrameOpen(false);
  }, []);
  return (
    <>
      <div className={styles.Main}>
        {/* <Navbar/> */}

        <Card
        title="Smart Question Generation"
        imageSrc="/Data points.gif"
      />
        {/* <InputComponent width="372px" />
        <InputComponent width="372px" label="First Name" />
        <InputComponent width="372px" height="6rem" />
        <Button
          width="10rem"
          color="#fff"
          bgColor="#8C52FF"
          svgPath="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
          buttonText="Send"
          onClick={openFrame}
        /> */}
      </div>
      <b>isFrameOpen{isFrameOpen}</b>
      {isFrameOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top center"
          onOutsideClick={closeFrame}
        >
          <Frame onClose={closeFrame} />
        </PortalPopup>
      )}
    </>
  );
}

export default Component;
