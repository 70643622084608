import React from 'react';
import styles from './styles.module.css';

const InputComponent = ({ width,height, color, focusBgColor,label }) => {
  const inputStyle = {
    width: width || '300px',
    height: height,
    borderColor: color || '#8C52FF',
    backgroundColor: color ? 'none' : 'transparent',
  };

  const labelStyle = {
    color: color ? color : '#8C52FF',
  };

  const inputFocusStyle = {
    borderColor: color || '#8C52FF',
    backgroundColor: focusBgColor || '#fff',
  };

  return (
    <div className={styles.inputGroup}>
      <input required type="text" name="text" autoComplete="off" className={styles.input} style={inputStyle} />
      <label className={styles.userLabel} style={labelStyle}>{label}</label>
    </div>
  );
}

export default InputComponent;
