import { useState } from 'react'
import styles from './styles.module.css';

const Navbar = () => {

    
    
    return (
        <div className={styles.NavbarContainer}>
        <nav className={`${styles.navbar}`}>
            <a href='#home' className={`${styles.logo}`}>AQPG</a>
            <ul className={`${styles.navMenu}`}>
                <li >
                    <a href='#home' className={`${styles.navLink}`}>HOME</a>
                </li>
                <li >
                    <a href='#about' className={`${styles.navLink}`}>ABOUT</a>
                </li>
                <li >
                    <a href='#features' className={`${styles.navLink}`}>FEATURES</a>
                </li>
                <li >
                    <a href='#contact' className={`${styles.navLink}`}>CONTACT</a>
                </li>
            </ul>
            <div className={styles.login}>
                <button className={styles.loginbtn}>
                    <p className={styles.loginp}>LOGIN</p>
                </button>
            </div>
        </nav>
        </div>
    );
}
export default Navbar;