import React from 'react';
import styles from './styles.module.css';

const Card = ({ title, imageSrc }) => {
  return (
    <div className={styles.card}>
      <div className={styles.cardcontents}>
      <b className={styles.cardtxt}>{title}</b>
      <img
        className={styles.cardimg}
        alt=""
        src={imageSrc}
      />
      </div>
    </div>
  );
}

export default Card;
