import React from 'react';
import styles from './styles.module.css';

const Button = ({ width, color, bgColor, svgPath, buttonText ,onClick }) => {
  const buttonStyle = {
    width: width || 'auto',
    color: color || '#000',
    backgroundColor: bgColor || '#8C52FF',
  };

  return (
    <button className={styles.button} style={buttonStyle} onClick={onClick}>
      <div className={styles.svgWrapper}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          className={styles.svg}
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path fill="currentColor" d={svgPath}></path>
        </svg>
      </div>
      <span>{buttonText}</span>
    </button>
  );
}

export default Button;
